import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { Button } from '../components/styles/button.styled';
import { Section } from '../components/styles/section.styled';

interface Props {
  data: {
    mdx: {
      id: string;
      body: string;
      frontmatter: {
        title: string;
        date: string;
        image: IGatsbyImageData;
      };
    };
  };
}

const PageTemplate = ({ data: { mdx } }: Props) => {
  const image = getImage(mdx.frontmatter.image);
  return (
    <Layout>
      <Section className="blog">
        <h1>{mdx.frontmatter.title}</h1>
        <h4
          style={{
            color: 'gray',
            fontWeight: 'normal',
          }}
        >
          {mdx.frontmatter.date} by Honza Pav
        </h4>
        <GatsbyImage image={image} alt={mdx.frontmatter.title} />
        <MDXProvider components={Button}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Section>
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date(formatString: "DD. MM. YYYY")
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
